























import { Component, Vue, Ref } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ListItemBox from '@/components/organisms/ListItemBox.vue'
import ModalTeacherInput, { ModalParams } from '@/components/organisms/ModalTeacherInput.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    ListItemBox,
    ModalTeacherInput,
  },
})
export default class Attribute extends Vue {
  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: 'グループ', active: true },
  ]

  private attributeDatas: {
    id: number
    name: string
  }[] = []

  @Ref() modalTeacherInput!: ModalTeacherInput

  private modalInfo: ModalParams = {}

  private showModalInputAdd(): void {
    this.modalInfo = {
      title: 'グループ追加',
      buttonName: '追加',
      value: '',
      maxLength: 20,
      onClick: this.create,
    }
    this.modalTeacherInput.show()
  }

  private showModalInputEdit(index: number): void {
    this.modalInfo = {
      id: this.attributeDatas[index].id,
      title: 'グループ編集',
      buttonName: '保存',
      value: this.attributeDatas[index].name,
      maxLength: 20,
      onClick: this.update,
    }
    this.modalTeacherInput.show()
  }

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private mounted() {
    this.loadDatas()
  }

  private async loadDatas() {
    await Vue.prototype.$http.httpWithToken.get(`/user_attributes?branchId=${this.branchId}`).then((res: any) => {
      this.attributeDatas = res.data.userAttributes.map((userAttribute: { id: number; title: string }) => {
        return {
          id: userAttribute.id,
          name: userAttribute.title,
        }
      })
    })
  }

  private async update(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .put(`/user_attributes/${this.modalInfo.id}`, { title: this.modalInfo.value })
      .then(() => {
        this.modalTeacherInput.hide()
        this.loadDatas()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('グループが重複しているため登録できません。')
        } else if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  private async create(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .post('/user_attributes', { branchId: this.branchId, title: this.modalInfo.value })
      .then(() => {
        this.modalTeacherInput.hide()
        this.loadDatas()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('グループが重複しているため登録できません。')
        }
      })
  }

  private async destroy(id: number): Promise<void> {
    if (!confirm('削除しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/user_attributes/${id}`)
      .then(() => {
        this.loadDatas()
      })
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert('生徒が存在しているため削除できません。')
        } else if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }
}
